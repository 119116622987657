import React from "react";
import taskAnalysis from "../../media/projects/onboarding/task-analysis.png";

const TaskAnalysis = () => {
    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"text-base"}>
                Our onboarding process data flow looked something like this when performing <b>Integrated Task Analysis</b> which combines the structural approach of HTA (breaking down tasks into sub-tasks and understanding task flow) with the cognitive focus of CTA (capturing the decision-making, mental processes, and knowledge required to perform tasks).
            </div>
            <img src={taskAnalysis} alt={"Task Analysis"} className={"w-full h-auto"}/>
        </div>
    )
}

export default TaskAnalysis;