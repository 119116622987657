export const publications = [
    {
        name: "Strategizing AI-powered middleware system design for Human Resources Data Management (Pre-print)",
        link: "https://www.techrxiv.org/users/691309/articles/682022-strategizing-ai-powered-middleware-system-design-for-human-resources-data-management",
        description: "The growing adoption of iPaaS (Integration Platform as a Service) solutions in organisations has led to an increased need for efficient and tailored middleware systems to manage the various data types, including various use cases of artificial intelligence and automation. While many iPaaS solutions offer similar core utilities, the differences in configuration options, the availability of connectors, range of features and the ease-of-use can greatly impact their efficacy while handling specific types of data. Most iPaaS solutions try to fit the one-size-fits-all model so that all kinds of data can be manipulated through a single iPaaS medium. Differences in data types poses a limitation to such a model. This paper aims to explore the challenges faced during best practices of the current middleware systems focussing on HR (Human Resources) data, as well as potential AI applications in the design of the iPaaS. The study also highlights the importance of considering factors such as data security, data governance, and user friendliness when selecting an iPaaS solution for HR data management and possible AI-driven strategies.",
        date: "2023-04-29",
        dateName: "April 29th, 2023",
    },
    {
        name: "Transferring Learnt Features from Deep Neural Networks trained on Structured Data",
        link: "https://dl.acm.org/doi/abs/10.1145/3529399.3529410",
        description: "Structured data is a widely used type of data with numerous applications in training machine learning models. However, training deep learning models require a lot of data, which may not be present for all use-cases. In addition to this, training these models could get very expensive as the data increases. Transfer learning can be a solution to these problems. It involves reusing features from trained models on the same or similar tasks, however, it has not been explored much for structured data yet. In this paper, an approach is proposed to transfer learnt features from the embedding layers present commonly in deep neural networks for structured data along with a format for effective portability of these trained embeddings. Experimentally, it is observed that the proposed method resulted in faster training and the model parameters start at a better point compared to parameters of a randomly initialized model, resulting in lesser training costs as well.",
        date: "2022-06-10",
        dateName: "June 10th, 2022",
    }
]