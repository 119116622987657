import solarForce from '../media/solar-force.png'
import ping from '../media/ping.png'
import money from '../media/money.png'
import productiva from '../media/productiva.png'

export const designs = [
    {
        name: "Solar Force (Won Best Design)",
        description: "The planets align here",
        src: solarForce,
        link: "https://www.behance.net/gallery/101767233/Solar-Force"
    },
    {
        name: "Ping",
        description: "An online meeting platform",
        src: ping,
        link: "https://www.behance.net/gallery/119482489/Ping-An-Online-Meeting-Platform"
    },
    {
        name: "Money",
        description: "",
        src: money,
        link: "https://www.behance.net/gallery/96643517/Money"
    },
    {
        name: "Productiva",
        description: "",
        src: productiva,
        link: "https://www.behance.net/gallery/98855753/Productiva"
    }
]