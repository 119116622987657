import React from "react";
import diaryStudy1 from "../../media/projects/onboarding/diaryStudy1.png";
import diaryStudy2 from "../../media/projects/onboarding/diaryStudy2.png";

const DiaryStudy = () => {
    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"text-base flex justify-around"}>
                <div>
                    Duration: 1 week
                </div>
                <div>
                    Profile: New Grad at Workday
                </div>
            </div>
            <div className={"grid grid-cols-2 gap-6"}>
                <div className={"col-span-1 flex flex-col gap-4"}>
                    <div className={"text-base font-bold"}>
                        Our Findings
                    </div>
                    <img src={diaryStudy1} alt={"Findings"} className={"w-full h-auto"}/>
                </div>
                <div className={"col-span-1 flex flex-col gap-4"}>
                    <div className={"text-base font-bold"}>
                        Workflow Efficiency
                    </div>
                    <img src={diaryStudy2} alt={"Workflow Efficiency"} className={"w-full h-auto"}/>
                </div>
            </div>
        </div>
    )
}

export default DiaryStudy;