import React from 'react';
import {ongoingProjects} from "../../content/projects";
import {useParams} from "react-router-dom";

const ARNavigationSystem = () => {
    const {id} = useParams();


    const project = ongoingProjects.filter(x => x.id === 'ar-guided-safety-navigation-system')[0];

    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                <span
                                    className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    {project.name}
                                </span>
                            </h1>
                        </header>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-8">
                                <div className={"grid grid-cols-1 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        {project.detailedDescription.map((x, i) => {
                                            return (
                                                <div key={i} className="text-base text-zinc-600 dark:text-zinc-400">
                                                    {x}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Research Methods
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-1 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        {project.researchMethods.map((x, i) => {
                                            return (
                                                <p className={"text-base text-zinc-600 dark:text-zinc-400"}>
                                                    {x}
                                                </p>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Research Findings and User Needs
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-1 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        <table className={"border-collapse table-auto"}>
                                            <thead>
                                            <tr className={"text-violet-500"}>
                                                <th className={"border-b border-zinc-600 dark:border-zinc-600 text-base p-4"}>
                                                    Research Findings
                                                </th>
                                                <th className={"border-b border-zinc-600 dark:border-zinc-600 text-base p-4"}>
                                                    User Needs
                                                </th>
                                                <th className={"border-b border-zinc-600 dark:border-zinc-600 text-base p-4"}>
                                                    Design Implications
                                                </th>
                                                <th className={"border-b border-zinc-600 dark:border-zinc-600 text-base p-4"}>
                                                    Source
                                                </th>
                                            </tr>
                                            </thead>
                                            {project.researchFindings.map((x, i) => {
                                                return (
                                                    <tr>
                                                        <td className={"border-b border-zinc-600 dark:border-zinc-600 text-base text-zinc-600 dark:text-zinc-400 p-4"}>
                                                            {x.researchFindings}
                                                        </td>
                                                        <td className={"border-b border-zinc-600 dark:border-zinc-600 text-base text-zinc-600 dark:text-zinc-400 p-4"}>
                                                            {x.userNeeds}
                                                        </td>
                                                        <td className={"border-b border-zinc-600 dark:border-zinc-600 text-base text-zinc-600 dark:text-zinc-400 p-4"}>
                                                            {x.designImplications}
                                                        </td>
                                                        <td className={"border-b border-zinc-600 dark:border-zinc-600 text-base text-zinc-600 dark:text-zinc-400 p-4"}>
                                                            {x.source}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-16"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Product Features
                                    </span>
                                </h2>
                                {project.productFeatures.map((x, i) => {
                                    return (
                                        <div className={"grid grid-cols-2 gap-8"}>
                                            <div
                                                className={`flex flex-col gap-6 col-span-1 ${i % 2 === 0 ? 'order-first' : 'order-last'}`}>
                                                <h3 className={"text-2xl font-semibold text-zinc-800 dark:text-zinc-100"}>
                                                    {x.name}
                                                </h3>
                                                {x.description.map((y, j) => {
                                                    return (
                                                        <p className={"text-base text-zinc-600 dark:text-zinc-400"}>
                                                            {y}
                                                        </p>
                                                    )
                                                })}
                                            </div>
                                            <div className={"flex flex-col gap-6 col-span-1"}>
                                                <img src={x.img} alt={x.name} className={"w-full h-auto"}/>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>

                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className={"mt-8 sm:mt-10 flex flex-col gap-8"}>
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Storyboard
                                    </span>
                                </h2>
                                <div className={"flex flex-col gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        <p className={"text-base text-zinc-600 dark:text-zinc-400"}>
                                            {project.storyboard.text}
                                        </p>
                                    </div>
                                    <div className={"flex flex-col gap-6 col-span-1"}>
                                        <img src={project.storyboard.img} alt={"Storyboard"} className={"w-full h-auto"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ARNavigationSystem;