import thirdPartyActions from '../media/articles/third-party-actions.png'
import snowyChristmas from '../media/articles/snowy_christmas.png'
import letItSnow from '../media/articles/let_it_snow.png'
import snowmanPumpkin from '../media/articles/snowman_pumpkin.png'
import snowfall from '../media/articles/snowfall.png'
import mdf_successfactors from '../media/articles/mdf_successfactors.png'
import workato from '../media/articles/workato.jpg'
import sap_boomi from '../media/articles/sap_boomi.png'
import firebase from '../media/articles/firebase.png'

export const articles = [
    {
        name: "Understanding ServiceNow Part 4 : A SNow-y Christmas",
        path: "/articles/understanding-servicenow-part-4",
        id: "understanding-servicenow-part-4",
        link: "https://integrtr.com/understanding-servicenow-part-4/",
        description: "Hello to all ServiceNow enthusiasts! Welcome back to the fourth and final part of the Understanding ServiceNow blog series. It’s the end of the year, and Christmas is right around the corner. In this holiday spirit, let’s rejoice with some ornamental lights, a dark green tree, some red home decor and a little ServiceNow learnings by the side 😊",
        date: "2022-12-19",
        dateName: "December 19th, 2022",
        img: snowyChristmas,
        content: [
            {
                type: "p",
                content: [
                    {
                        type: "span",
                        text: "Hello to all ServiceNow enthusiasts! Welcome back to the fourth and final part of the "
                    },
                    {
                        type: "b",
                        text: "Understanding ServiceNow "
                    },
                    {
                        type: "span",
                        text: "blog series. It’s the end of the year, and Christmas is right around the corner. In this holiday spirit, let’s rejoice with some ornamental lights, a dark green tree, some red home decor and a little ServiceNow learnings by the side 😊"
                    }
                ]
            },
            {
                type: "p",
                text: "If you haven’t read the previous parts of this blog series, find them here:"
            },
            {
                type: "ol",
                content: [
                    {
                        type: "li",
                        content: [
                            {
                                type: "b",
                                text: "Understanding ServiceNow Part 1 : SNow-Fall : "
                            },
                            {
                                type: 'a',
                                href: "https://integrtr.com/understanding-servicenow-part-1/"
                            }
                        ]
                    },
                    {
                        type: "li",
                        content: [
                            {
                                type: "b",
                                text: "Understanding ServiceNow Part 2 : The SNow-man’s Pumpkin : "
                            },
                            {
                                type: 'a',
                                href: "https://integrtr.com/understanding-servicenow-part-2/"
                            }
                        ]
                    },
                    {
                        type: "li",
                        content: [
                            {
                                type: "b",
                                text: "Understanding ServiceNow Part 3 : Let it SNow : "
                            },
                            {
                                type: 'a',
                                href: "https://integrtr.com/understanding-servicenow-part-3 /"
                            }
                        ]
                    }
                ]
            },
            {
                type: "p",
                content: [
                    {
                        type: "span",
                        text: "In this blog, we will be discussing ServiceNow’s position in the marketplace and how it connects with other third-party solutions. More precisely, we will be looking at how ServiceNow and "
                    },
                    {
                        type: "b",
                        text: "SAP SuccessFactors "
                    },
                    {
                        type: "span",
                        text: "could work together. By the end of this blog you will be able to:"
                    }
                ]
            },
            {
                type: "ul",
                content: [
                    {
                        type: "li",
                        content: [
                            {
                                type: "span",
                                text: "Generate an "
                            },
                            {
                                type: "b",
                                text: "X.509 Certificate "
                            },
                            {
                                type: "span",
                                text: "on SuccessFactors"
                            }
                        ]
                    },
                    {
                        type: "li",
                        content: [
                            {
                                type: "span",
                                text: "Design and use the "
                            },
                            {
                                type: "b",
                                text: "Action Designer "
                            },
                            {
                                type: "span",
                                text: "on ServiceNow"
                            }
                        ]
                    },
                    {
                        type: "li",
                        content: [
                            {
                                type: "span",
                                text: "Establish "
                            },
                            {
                                type: "b",
                                text: "OAuth 2.0 "
                            },
                            {
                                type: "span",
                                text: "authentication on ServiceNow"
                            }
                        ]
                    },
                    {
                        type: "li",
                        content: [
                            {
                                type: "span",
                                text: "Load records into an "
                            },
                            {
                                type: "b",
                                text: "Import Set "
                            },
                            {
                                type: "span",
                                text: "with the help of a "
                            },
                            {
                                type: "b",
                                text: "Data Source"
                            }
                        ]
                    }
                ]
            },
            {
                type: "p",
                text: "Without further ado, let’s get started!"
            },
            {
                type: 'h2',
                text: "Third-party Options"
            },
            {
                type: "p",
                text: "There are different ways of connecting any third-party software to ServiceNow. As discussed in the previous parts of this blog series, you can do so with the help of REST messages, installed spokes, and actions offered by Flow Designer. Additionally, you can also build your own custom REST request to make any outbound HTTP calls."
            },
            {
                type: "img",
                src: thirdPartyActions,
                alt: "Third Party Actions",
                caption: "Third Party Actions available from Flow Designer"
            }
        ]
    },
    {
        name: "Understanding ServiceNow Part 3 : Let it SNow",
        img: letItSnow,
        path: "/articles/understanding-servicenow-part-3",
        id: "understanding-servicenow-part-3",
        link: "https://integrtr.com/understanding-servicenow-part-3/",
        description: "Hello and welcome back to the third part of the Understanding ServiceNow blog series. We are well into Now-ember and it is time to cover up in the cold and embrace the new season of the year. In the previous part of the blog series, we covered a few basics of ServiceNow including how one can get started and navigate across the Now Platform.",
        date: "2022-11-17",
        dateName: "November 17th, 2022",
        content: []
    },
    {
        name: "Understanding ServiceNow Part 2 : The SNow-man’s Pumpkin",
        img: snowmanPumpkin,
        path: "/articles/understanding-servicenow-part-2",
        id: "understanding-servicenow-part-2",
        link: "https://integrtr.com/understanding-servicenow-part-2/",
        description: "Welcome back to the second part of the Understanding ServiceNow blog series! In the previous blog we spoke about the numerous features that can help you achieve superior automation in your data management with ServiceNow. In our first part (read here, if you have not yet), we talked about the features of ServiceNow and how ServiceNow can best suit all your business requirements. This blog will help you begin your ServiceNow journey. We will be going through some specific applications that are involved in upserting a record externally into an existing table in ServiceNow.",
        date: "2022-10-28",
        dateName: "October 28th, 2022",
        content: []
    },
    {
        name: "Understanding ServiceNow Part 1 : SNow-Fall",
        img: snowfall,
        path: "/articles/understanding-servicenow-part-1",
        id: "understanding-servicenow-part-1",
        link: "https://integrtr.com/understanding-servicenow-part-1/",
        description: "It’s that time of the year when we find autumn leaves on the ground and IT/HR teams in the thick of blending, coalescing or consolidating all kinds of data. In this final quarter of the year, integrations of every hue are at its peak or towards its end. But, amidst all of this frenzy, are you facing any missing link? Do you require some quick automation? Looking for a new PaaS? With ServiceNow, you are just a REST/SOAP call away to use the powers and benefits that the platform has to offer.",
        date: "2022-10-10",
        dateName: "October 10th, 2022",
        content: []
    },
    {
        name: "MDF Objects in SAP SuccessFactors",
        img: mdf_successfactors,
        path: "/articles/mdf-objects-in-sap-successfactors",
        id: "mdf-objects-in-sap-successfactors",
        link: "https://integrtr.com/mdf-objects-in-sap-successfactors/",
        description: "In the SAP world, SuccessFactors is an integral cloud-based solution for managing various HR functions. To elaborate just a little more on what is SuccessFactors — casually known as SF or SFSF, SuccessFactors is an HR tool that provides cloud-based software for human capital management (HCM), using the software-as-a-service (SaaS) model.",
        date: "2022-09-02",
        dateName: "September 2nd, 2022",
        content: []
    },
    {
        name: "Working Things Out With Workato",
        img: workato,
        path: "/articles/working-things-out-with-workato",
        id: "working-things-out-with-workato",
        link: "https://integrtr.com/working-things-out-with-workato/",
        description: "Workato is one of the up-and-coming iPaaS options that are available today in the integration world. In this blog, we will be taking a look at the basic components and features supported by Workato when building an integration flow.",
        date: "2022-07-18",
        dateName: "July 18th, 2022",
        content: []
    },
    {
        name: "A comparative study on SAP CI and Dell Boomi",
        img: sap_boomi,
        path: "/articles/a-comparative-study-on-sap-ci-and-dell-boomi",
        id: "a-comparative-study-on-sap-ci-and-dell-boomi",
        link: "https://integrtr.com/a-comparative-study-on-sap-ci-and-dell-boomi/",
        description: "The cloud is the most revered destination for most integrations at present. Today there is a rising demand for developers to support multiple projects as new languages, procedures and applications are introduced. Platforms now need to support many different use cases, like automation, customer service, web experience, e-commerce, data analysis as well as enterprise software development. One of the most interesting scenarios lies in data integration on the cloud. This blog will explore the features of SAP Cloud Integration (also known as SAP CPI/CI) and Dell Boomi to help you ascertain which of them is more suitable for your business requirements. If we specifically consider ERP integrations, the task that looms big is connecting ERP software to other applications and data sources and synchronizing them. Usually, the expected end product is a single solution for the entire ERP spectrum, irrespective of where the data originates from.",
        date: "2022-02-07",
        dateName: "February 7th, 2022",
        content: []
    },
    {
        name: "5 Things You Missed About Firebase Authentication in Android",
        img: firebase,
        path: "/articles/5-things-you-missed-about-firebase-authentication-in-android",
        id: "5-things-you-missed-about-firebase-authentication-in-android",
        link: "https://medium.com/@amethystzeal/5-things-you-missed-about-firebase-authentication-in-android-33b67338ae37",
        description: "Almost every app requires some level of authorization in order for one to gain access. Most often, validating a username/password is enough, but sometimes, we need a deeper level of permissions to allow restricted access for certain resources. To build such a system may be extremely complex and time consuming. With Firebase authentication users sign in to your application using a system they already use and trust. Your app can then save the user’s data securely in the cloud and provide the same personalized experience across all of their devices.",
        date: "2020-09-19",
        dateName: "September 19th, 2020",
        content: []
    }
]