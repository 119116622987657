import React from "react";
import semiStructuredInterview from "../../media/projects/onboarding/semi-structured-interview.png";
import semiStructuredInterviewHelp from "../../media/projects/onboarding/semi-structured-interview-help.png";

const SemiStructuredInterview = () => {
    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"text-base"}>
                We interviewed 8 people to get insights about which features do Software engineers and managers find the most valuable during an onboarding process.
            </div>
            <div className={"grid grid-cols-2 gap-6"}>
                <div className={"col-span-1"}>
                    <img src={semiStructuredInterview} alt={"Semi-structured Interview"} className={"w-full h-auto"}/>
                </div>
                <div className={"col-span-1 flex flex-col gap-4"}>
                    <div className={"text-base"}>
                        This helped us:
                    </div>
                    <img src={semiStructuredInterviewHelp} alt={"Semi-structured Interview Help"} className={"w-full h-auto"}/>
                </div>
            </div>
        </div>
    )
}

export default SemiStructuredInterview;