import studee from '../media/projects/studee.png'
import matlab from '../media/projects/matlab.png'
import c_plus_plus from '../media/projects/c++.png'
import stock from '../media/projects/stock.png'
import job from '../media/projects/job.png'
import aws from '../media/projects/aws.png'
import ar from '../media/projects/ar.png'
import ar_system from '../media/projects/ar-ar-system.png'
import safety_score from '../media/projects/ar-safety-system.png'
import object_detection_framework from '../media/projects/ar-object-detection.png'
import storyboard from '../media/projects/storyboard.png'
import SemiStructuredInterview from "../components/projects/semiStructuredInterview";
import DiaryStudy from "../components/projects/diaryStudy";
import TaskAnalysis from "../components/projects/taskAnalysis";

export const projects = [
    {
        name: "Studee",
        description: "Studee is a student portal application that helps students and teachers communicate both virtually and physically by keeping students ENGAGE-ed to use the platform with the help of a Credit-based system.",
        src: studee,
        link: "https://studee.ishitadatta.com"
    },
    {
        name: "Mini C++ Compiler",
        description: "This project for a Mini Compiler for the C++ programming language was made for the course title Compiler Design. The project focuses on generating an intermediate code for the language for specific constructs. It works for constructs such as conditional statements, loops, and the ternary operator. The main functionality of the project is to generate an optimized intermediate code for the given C++ source code.",
        src: c_plus_plus,
        link: "https://github.com/ishitadatta/mini-cpp-compiler"
    },
    {
        name: "S&P Stock Analysis",
        description: "This repository consists of the various models used to train and test the S&P 500 dataset to analyze and predict stocks for the course Data Analytics (UE18CS312).",
        src: stock,
        link: "https://github.com/ishitadatta/S-P-stock-analysis"
    },
    {
        name: "Eyeball Detection with MATLAB",
        description: "Eye detection and tracking has been an active research field in the past years as it adds convenience to a variety of applications. Eye-gaze tracking is been considered as untraditional method of Human Computer Interaction. Eye tracking is considered as the easiest alternative interface methods. For eye tracking and eye detection several different approaches have been proposed and used to implement different algorithms for these technologies. Generally, an eye tracking and detection system can be divided into four steps: Face detection, eye region detection, pupil detection and eye tracking.",
        src: matlab,
        link: "https://github.com/ishitadatta/EyeballDetection"
    },
    {
        name: "Odd Job Finder",
        description: "An odd job finder is a platform independent flutter app that helps you hire people for odd jobs at home as well as apply for the same. There is a vast requirement for housekeeping services. There is no concrete method in acquiring such help other than agencies that are very expensive and do not seem to work very well. This app helps connect an individual to find odd job oppurtunites or even house keeping services located near them. The duration is flexible and the requirements are chosen by the job seeker. This app will help people get the required jobs done at a convenient method. It helps people on the move to get small jobs as well conveniently.",
        src: job,
        link: "https://github.com/ishitadatta/OddJobFinder"
    }
]

export const ongoingProjects = [
    {
        name: "Augmented reality-guided safety navigation system",
        description: "We are building an AR system that helps residents of Midtown Atlanta navigate from one point in midtown to another point based on factors that are recommended based on street lighting, frequent footfall, pedestrian traffic, time of day and other factors.",
        src: ar,
        id: "ar-guided-safety-navigation-system",
        link: "/projects/ar-guided-safety-navigation-system",
        detailedDescription: [
            "This project is focused on new residents to Midtown Atlanta and helping them navigate the area safely. Midtown can be unsafe for walking especially at night time, and as such new residents may be hesitant to explore the city when they are not familiar with the environment." +
            "Key design requirements include customization and prioritization of safety factors, discreet and non-visual feedback, connecting with friends, family, and local authorities, support for community engagement, and combining safety and navigation features into a single solution."
        ],
        researchMethods: [
            "We did a lot of indirect and direct research methods. Our direct research methods involved semi-structured interviews and surveys, responses from which built our user needs findings and affinity model."
        ],
        researchFindings: [
            {
                researchFindings: "Users reported various factors that contributed to their perception of safety: presence of houseless individuals, pedestrian traffic, lighting, security and police presence, open businesses, and sidewalk conditions.",
                userNeeds: "User needs to be able to choose what factors are the most important considerations when deciding on the safest route.",
                designImplications: "Customization and personalization when it comes to identifying safety factors is needed, as male and female users will prioritize different factors in their assessment of safety.",
                source: "Indirect research, interview, survey"
            },
            {
                researchFindings: "Users found it safer when traveling with someone rather than walking alone especially at night and especially if female, because in a group there is less chance to be harassed and there are more people to look out for threats.",
                userNeeds: "There is high desirability for communal aspects in the determination of safety",
                designImplications: "Users should have access to a walking companion in instances where they feel unsafe by themselves.",
                source: "Interview, Indirect research"
            },
            {
                researchFindings: "Users do not feel very connected to the broader community in Midtown, as it is part of a big city where it is difficult to make person-to-person connections, and the opportunities for community engagement are not as widely promoted outside of existing social circles. ",
                userNeeds: "Users need the resources and opportunities to get connected with the community and meet new people.",
                designImplications: "There needs to be a focus on increasing awareness and support of community engagement.",
                source: "Interview"
            },
            {
                researchFindings: "Users often feel more unsafe or worried when they receive certain emergency alerts regarding crimes and hazards in the Midtown area.",
                userNeeds: "Users should receive relevant safety information without being overwhelmed or scared.",
                designImplications: "Urgent data, statistics, and information should be presented in a way that is appropriate, relevant to and considerate of the user.",
                source: "Interview"
            },
            {
                researchFindings: "Users do not want to be looking at their devices while navigating, as it makes them look lost, uncertain, or distracted which can make them more likely to be targeted.",
                userNeeds: "Users need to be able to navigate without being or appearing to be vulnerable. ",
                designImplications: "There should be a discreet and/or non-visual solution to convey relevant information without requiring excess attention. This is particularly important for female users, because they report feeling more susceptible to harassment.",
                source: "Interview"
            },
            {
                researchFindings: "Users feel safer about friends being aware about their location when navigating on the street, because their friends can be called on for support.",
                userNeeds: "Users need to have those in their trusted circle aware of their whereabouts in order to feel more safe when they are alone.",
                designImplications: "There should be a solution that automatically connects the user to their friends, family, and/or community and updates them appropriately.",
                source: "Interview"
            },
            {
                researchFindings: "One combined safety and navigation solution is preferred over multiple disparate products, as users do not like downloading or depending on a lot of apps and wish for deeper integrations between apps.",
                userNeeds: "Users wish to have one application over having multiple apps for different functions/features.",
                designImplications: "There should be a single multi-purpose solution that integrates both safety and navigation features in a seamless way.",
                source: "Interview"
            },
        ],
        productFeatures: [
            {
                name: "AR System",
                description: [
                    "Provides users with real-time augmented safety information through highlighting safer pathways, factoring in pedestrian density, lighting, and other relevant data.",
                    "Gives clear, immediate visual cues, ensuring users choose safer routes while staying aware of their surroundings.",
                    "Design Requirements Met: The AR system serves as a single multi-purpose solution and allows users to customize their route preferences based on prioritized safety factors."
                ],
                img: ar_system
            },
            {
                name: "Safety Score",
                description: [
                    "Analyzes a variety of factors to calculate a personalized safety score for each route by accommodating what the user’s “notion of safety” is.",
                    "The user receives a ranked recommendation of pathways, allowing them to choose the safest and most efficient route. Our direct research methods gave us the list of factors that would feed into the input parameters of a recommendation system to calculate the scores and output safety scores based on the user’s preference and street circumstance.",
                    "Design Requirements Met: The scoring system uses Customization and Prioritization of Safety Factors to generate a personalized safety score for each route that aligns with the user's unique safety priorities."
                ],
                img: safety_score
            },
            {
                name: "Object Detection Framework",
                description: [
                    "Helps identify key elements in the user's environment, such as pedestrians, sidewalks, crosswalks, street lighting, and hazards like broken pavements.",
                    "Scans the surroundings for important features that contribute to a safer journey. Machine learning frameworks such as YOLO is to be used to detect objects on the road.",
                    "Design Requirements Met: Customization and Prioritization of Safety Factors, as the user can choose to highlight / emphasize the factors that are most important to them to be detected"
                ],
                img: object_detection_framework
            }
        ],
        storyboard: {
            text: "An augmented reality system that helps users navigate safely through a city while integrating object detection, community alerts, and real-time updates.",
            img: storyboard
        }
    },
    {
        name: "Onboarding SWEs using Gen AI - Industry collaboration with AWS",
        description: "As part of this project we are conducting extensive user research to understand gaps in existing onboarding software.",
        src: aws,
        id: "onboarding-swes-using-gen-ai",
        link: "/projects/onboarding-swes-using-gen-ai",
        problemStatement: [
            "This project was an industry sponsored project with AWS as our industry sponsor. Our Problem Statement was about how we can use generative AI to facilitate the onboarding process for new grad and intern software development engineers?"
        ],
        significance: [
            {
                title: "Benefits",
                content: [
                    "Streamlining the onboarding process for new graduates and interns",
                    "Helping them quickly adapt to the work environment",
                    "Reduced stress",
                    "Improved efficiency"
                ]
            },
            {
                title: "Innovations",
                content: [
                    "Leveraging generative AI to automate access to relevant information",
                    "Personalized learning pathways",
                    "Enhancing the onboarding experience",
                    "Reduced manual tasks"
                ]
            },
            {
                title: "Impact",
                content: [
                    "Providing personalized learning experiences",
                    "Enabling new hires to focus on core responsibilities",
                    "Improved retention, satisfaction, and productivity in the early stages of employment",
                ]
            }
        ],
        process: [
            {
                title: "Target User/Subject Groups",
                content: [
                    "New graduate and intern software engineers",
                    "Early career individuals transitioning from academic learning to practical software development roles"
                ]
            },
            {
                title: "Behavior of Interest",
                content: [
                    "Onboarding efficiency: how quickly and effectively new hires can become productive",
                    "Learning and adapting to company-specific workflows, tools, and coding standards",
                    "Engagement with AI-driven support system during the onboarding phase"
                ]
            },
            {
                title: "Stakeholders",
                content: [
                    "Primary Stakeholders: Software engineers, software development managers, HR, onboarding teams",
                    "Secondary Stakeholders: Project teams, senior engineers (mentors), learning & development departments"
                ]
            },
            {
                title: "Context of Use",
                content: [
                    "Onboarding occurs in hybrid or remote environments with varying levels of direct human mentorship",
                    "Integration with Development Environments (IDEs), version control systems, and other technical tools",
                    "Real-time AI assistance providing learning resources, task guidance, and coding support"
                ]
            },
            {
                title: "Business/Market Solution",
                content: [
                    "Tech industry faces high comptetion for talent; efficient onboarding can reduce time-to-productivity and increase retention",
                    "Need for scalable solutions to onboard large cohorts of new grads efficiently",
                    "Generative AI provides opportunities for personalized, real-time onboarding support that traditional methods may not scale well to deliver"
                ]
            }
        ],
        researchMethods: [
            {
                name: "Structured Interview",
            },
            {
                name: "Semi-structured Interview",
                component: <SemiStructuredInterview/>
            },
            {
                name: "Diary Study",
                component: <DiaryStudy/>
            },
            {
                name: "SWOT Analysis",
            },
            {
                name: "Task Analysis",
                component: <TaskAnalysis/>
            },
            {
                name: "Website Walkthrough",
            },
            {
                name: "Competitive Analysis",
            },
            {
                name: "Heuristic Evaluation - Amazon Q",
            },
            {
                name: "Case Study - Agentic AI",
            },
            {
                name: "Stakeholder Survey",
            }
        ],
    }
]