export const pages = [
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Projects",
        path: '/projects'
    },
    {
        name: "Research",
        path: '/research'
    },
    {
        name: "Mentorship",
        path: '/mentorship'
    },
    // {
    //     name: "Courses",
    //     path: '/courses'
    // },
    // {
    //     name: "Designs",
    //     path: '/designs'
    // },
]