import React, {useState, useEffect} from 'react';
import {GitHub, Instagram, LinkedIn, YouTube} from "../media/svg";
import {articles} from "../content/articles";
import {work} from "../content/work";
import {Link} from "react-router-dom";
import {projects, ongoingProjects} from "../content/projects";
import axios from 'axios';

const Home = () => {
    const hello = "Hey!";
    const title = "I'm Ishita";
    const subtitle = "A software developer, consultant and also a grad student at Georgia Tech.";

    const [displayedHello, setDisplayedHello] = useState('');
    const [displayedTitle, setDisplayedTitle] = useState('');
    const [displayedSubtitle, setDisplayedSubtitle] = useState('');

    const [helloCompleted, setHelloCompleted] = useState(false);
    const [titleCompleted, setTitleCompleted] = useState(false);
    const [subtitleCompleted, setSubtitleCompleted] = useState(false);


    // Animate title (one letter at a time)
    useEffect(() => {
        let helloIndex = 0;
        setDisplayedHello("H");
        const helloInterval = setInterval(() => {
            helloIndex += 1;
            setDisplayedHello((prev) => prev + hello[helloIndex]);
            if (helloIndex >= hello.length - 1) {
                clearInterval(helloInterval);
                setHelloCompleted(true);
            }
        }, 50); // Adjust the speed (100ms per letter)
        return () => clearInterval(helloInterval);
    }, [hello]);

    // Animate title (one letter at a time)
    useEffect(() => {
        if (helloCompleted) {
            let titleIndex = 0;
            setDisplayedTitle('I');
            const titleInterval = setInterval(() => {
                titleIndex++;
                setDisplayedTitle((prev) => prev + title[titleIndex]);
                if (titleIndex >= title.length - 1) {
                    clearInterval(titleInterval);
                    setTitleCompleted(true);
                }
            }, 50); // Adjust the speed (100ms per letter)
            return () => clearInterval(titleInterval);
        }
    }, [title, helloCompleted]);

    // Animate subtitle (two letters at a time)
    useEffect(() => {
        if (titleCompleted) {
            let subtitleIndex = 0;
            setDisplayedSubtitle('A');
            const subtitleInterval = setInterval(() => {
                subtitleIndex += 1;
                setDisplayedSubtitle((prev) => prev + subtitle[subtitleIndex]);
                if (subtitleIndex >= subtitle.length - 1) {
                    clearInterval(subtitleInterval);
                    setSubtitleCompleted(true);
                }
            }, 10); // Adjust the speed (200ms per two letters)
            return () => clearInterval(subtitleInterval);
        }
    }, [subtitle, titleCompleted]);

    const getResume = () => {
        axios.get("https://fh7k7jtt3kkfljpfafsw323kvm0ffgcg.lambda-url.ap-south-1.on.aws/").then(response => {
            window.open(response.data.url)
        })
    }

    return (
        <>
            <div className="sm:px-8 mt-9">
                <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="relative px-4 sm:px-8 lg:px-12">
                        <div className="mx-auto max-w-2xl lg:max-w-5xl grid grid-cols-1 lg:grid-cols-2">
                            <div className="max-w-2xl col-span-1">
                                <div className="text-2xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                    {displayedHello}
                                    <span className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                        {displayedTitle}
                                    </span>
                                </div>
                                <div
                                    className="text-2xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    {displayedSubtitle}
                                </div>
                                <p className={`mt-6 ${subtitleCompleted ? 'animate-slideUp' : 'opacity-0'} duration-1000 text-base text-zinc-600 dark:text-zinc-400`}>
                                    My journey towards software development commenced during my school years and gained
                                    momentum as a Computer Science undergraduate specializing in <i>Machine Intelligence </i>
                                    and <i>Data Science</i>. I have previously worked at three different companies where I
                                    my role involved <i>high-performance computing, cloud computing, integration
                                    development</i> and <i>natural language processing</i>. My current focus is in
                                    <i> human activity recognition, soft-body simulations</i> and <i>augmented reality</i>.
                                    My commitment lies in assisting clients, customers, and knowledge seekers by
                                    actively contributing to both the enterprise world as well as the developer
                                    community.
                                </p>
                                <div className={`${subtitleCompleted ? 'animate-slideUp' : 'opacity-0'} mt-6 flex gap-6`}>
                                    <a className={"group -m-1 p-1"} aria-label={"Follow me on LinkedIn"}
                                       href={"https://www.linkedin.com/in/ishita-datta-44869296"}>
                                        <LinkedIn/>
                                    </a>
                                    <a className={"group -m-1 p-1"} aria-label={"Follow me on GitHub"}
                                       href={"https://github.com/ishitadatta"}>
                                        <GitHub/>
                                    </a>
                                    <a className={"group -m-1 p-1"} aria-label={"Follow me on Instagram"}
                                       href={"https://www.instagram.com/lifestylewithishita/"}>
                                        <Instagram/>
                                    </a>
                                    <a className={"group -m-1 p-1"} aria-label={"Follow me on YouTube"}
                                       href={"https://youtube.com/@amethystzeal9349"}>
                                        <YouTube/>
                                    </a>
                                </div>
                            </div>
                            <div className={"col-span-1 flex items-end"}>
                                <div className="space-y-10 lg:pl-16 xl:pl-24">
                                    <div className={`${subtitleCompleted ? 'animate-slideUp' : 'opacity-0'} rounded-2xl border border-zinc-100 p-6 dark:border-zinc-700/40`}>
                                        <h2 className="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                                            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"
                                                 className={"h-6 w-6 flex-none fill-black dark:fill-white"}>
                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                   strokeLinejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier"><title>new-star</title>
                                                    <g id="Layer_2" data-name="Layer 2">
                                                        <g id="invisible_box" data-name="invisible box">
                                                            <rect width="48" height="48" fill="none"></rect>
                                                        </g>
                                                        <g id="icons_Q2" data-name="icons Q2">
                                                            <path
                                                                d="M42.3,24l3.4-5.1a2,2,0,0,0,.2-1.7A1.8,1.8,0,0,0,44.7,16l-5.9-2.4-.5-5.9a2.1,2.1,0,0,0-.7-1.5,2,2,0,0,0-1.7-.3L29.6,7.2,25.5,2.6a2.2,2.2,0,0,0-3,0L18.4,7.2,12.1,5.9a2,2,0,0,0-1.7.3,2.1,2.1,0,0,0-.7,1.5l-.5,5.9L3.3,16a1.8,1.8,0,0,0-1.2,1.2,2,2,0,0,0,.2,1.7L5.7,24,2.3,29.1a2,2,0,0,0,1,2.9l5.9,2.4.5,5.9a2.1,2.1,0,0,0,.7,1.5,2,2,0,0,0,1.7.3l6.3-1.3,4.1,4.5a2,2,0,0,0,3,0l4.1-4.5,6.3,1.3a2,2,0,0,0,1.7-.3,2.1,2.1,0,0,0,.7-1.5l.5-5.9L44.7,32a2,2,0,0,0,1-2.9ZM18,31.1l-4.2-3.2L12.7,27h-.1l.6,1.4,1.7,4-2.1.8L9.3,24.6l2.1-.8L15.7,27l1.1.9h0a11.8,11.8,0,0,0-.6-1.3l-1.6-4.1,2.1-.9,3.5,8.6Zm3.3-1.3-3.5-8.7,6.6-2.6.7,1.8L20.7,22l.6,1.6L25.1,22l.7,1.7L22,25.2l.7,1.9,4.5-1.8.7,1.8Zm13.9-5.7-2.6-3.7-.9-1.5h-.1a14.7,14.7,0,0,1,.4,1.7l.8,4.5-2.1.9-5.9-7.7,2.2-.9,2.3,3.3,1.3,2h0a22.4,22.4,0,0,1-.4-2.3l-.7-4,2-.8L33.8,19,35,20.9h0s-.2-1.4-.4-2.4L34,14.6l2.1-.9,1.2,9.6Z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="ml-3">
                                                 Latest Ongoing Project
                                             </span>
                                        </h2>
                                        <ol className="mt-6 space-y-4">
                                            <Link to={"/projects/ar-guided-safety-navigation-system"}
                                                  className="flex gap-4">
                                                <div className="flex flex-auto col flex-wrap gap-2">
                                                    <div
                                                        className="flex items-center text-sm font-medium text-zinc-900 dark:text-zinc-100">
                                                        {ongoingProjects[0].name}
                                                    </div>
                                                    <div
                                                        className="flex items-center mr-auto text-xs text-zinc-400 dark:text-zinc-500"
                                                        aria-label="2024 until Present">
                                                        <time dateTime={"2024"}>
                                                            2024
                                                        </time>
                                                        <span aria-hidden="true"
                                                              className={['Summer ', ""].includes("2024") ? "hidden" : ""}>
                                                            —
                                                        </span>
                                                        <time dateTime={"2024"}>
                                                            Present
                                                        </time>
                                                    </div>
                                                    <div className={"text-sm text-zinc-400 dark:text-zinc-500"}>
                                                        {ongoingProjects[0].description}
                                                    </div>
                                                </div>
                                            </Link>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${subtitleCompleted ? 'animate-slideUp' : 'opacity-0'} sm:px-8 mt-24 md:mt-28`}>
                <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="relative px-4 sm:px-8 lg:px-12">
                        <div className="mx-auto max-w-2xl lg:max-w-5xl">
                            <div className="mx-auto grid max-w-xl grid-cols-1 gap-y-20 lg:max-w-none lg:grid-cols-2">
                                <div className="flex flex-col gap-16">
                                    {articles.slice(0, 1).map((article, i) => {
                                        return (
                                            <article key={i} className={"group relative flex flex-col items-start"}>
                                                <h2 className={"text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100"}>
                                                    <div
                                                        className={"absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl"}/>
                                                    <a href={article.link}>
                                                        <span
                                                            className={"absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"}/>
                                                        <span className={"relative z-10"}>
                                                            {article.name}
                                                        </span>
                                                    </a>
                                                </h2>
                                                <time
                                                    className={"relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500 pl-3.5"}
                                                    dateTime={article.date}>
                                                    <span className="absolute inset-y-0 left-0 flex items-center"
                                                          aria-hidden="true">
                                                        <span
                                                            className="h-4 w-0.5 rounded-full bg-zinc-200 dark:bg-zinc-500"/>
                                                    </span>
                                                    Latest Article
                                                </time>
                                                <p className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                                                    {article.description}
                                                </p>
                                                <div aria-hidden="true"
                                                     className="relative z-10 mt-4 flex items-center text-sm font-medium text-purple-400">
                                                    Read article
                                                    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true"
                                                         className="ml-1 h-4 w-4 stroke-current">
                                                        <path d="M6.75 5.75 9.25 8l-2.5 2.25" strokeWidth="1.5"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </div>
                                            </article>
                                        )
                                    })}
                                    <article className={"group relative flex flex-col items-center"}>
                                        <h2 className={"text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100"}>
                                            <div
                                                className={"absolute -inset-x-4 -inset-y-4 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl"}/>
                                            <Link to={"/articles"}>
                                                <div aria-hidden="true"
                                                     className="relative z-10 flex items-center text-sm font-medium text-purple-400">
                                                    More Articles
                                                </div>
                                            </Link>
                                        </h2>
                                    </article>
                                    {projects.slice(0, 1).map((x, i) => {
                                        return (
                                            <div key={i} className="group relative flex flex-col items-start">
                                                <div
                                                    className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
                                                    <img alt="" loading="lazy" width="32" height="32" decoding="async"
                                                         data-nimg="1"
                                                         className="rounded-full bg-zinc-100 object-cover dark:bg-zinc-800 h-8 w-8"
                                                         src={x.src} style={{color: "transparent"}}/>
                                                </div>
                                                <h2 className="mt-6 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                                                    <div
                                                        className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl"/>
                                                    <a href={x.link}>
                                                        <span
                                                            className="absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"/>
                                                        <span className="relative z-10">
                                                        {x.name}
                                                    </span>
                                                    </a>
                                                </h2>
                                                <time
                                                    className={"relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500 pl-3.5"}>
                                                    <span className="absolute inset-y-0 left-0 flex items-center"
                                                          aria-hidden="true">
                                                        <span
                                                            className="h-4 w-0.5 rounded-full bg-zinc-200 dark:bg-zinc-500"/>
                                                    </span>
                                                    A Full-stack Application
                                                </time>
                                                <p className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                                                    A virtual credit-based platform for students and teachers.
                                                </p>
                                                <p className="relative z-10 mt-6 flex text-sm font-medium text-zinc-400 transition group-hover:text-purple-500 dark:text-zinc-200">
                                                    <svg viewBox="0 0 24 24" aria-hidden="true"
                                                         className="h-6 w-6 flex-none">
                                                        <path
                                                            d="M15.712 11.823a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm-4.95 1.768a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm-2.475-1.414a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm4.95-1.768a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm3.359.53-.884.884 1.06 1.06.885-.883-1.061-1.06Zm-4.95-2.12 1.414-1.415L12 6.344l-1.415 1.413 1.061 1.061Zm0 3.535a2.5 2.5 0 0 1 0-3.536l-1.06-1.06a4 4 0 0 0 0 5.656l1.06-1.06Zm4.95-4.95a2.5 2.5 0 0 1 0 3.535L17.656 12a4 4 0 0 0 0-5.657l-1.06 1.06Zm1.06-1.06a4 4 0 0 0-5.656 0l1.06 1.06a2.5 2.5 0 0 1 3.536 0l1.06-1.06Zm-7.07 7.07.176.177 1.06-1.06-.176-.177-1.06 1.06Zm-3.183-.353.884-.884-1.06-1.06-.884.883 1.06 1.06Zm4.95 2.121-1.414 1.414 1.06 1.06 1.415-1.413-1.06-1.061Zm0-3.536a2.5 2.5 0 0 1 0 3.536l1.06 1.06a4 4 0 0 0 0-5.656l-1.06 1.06Zm-4.95 4.95a2.5 2.5 0 0 1 0-3.535L6.344 12a4 4 0 0 0 0 5.656l1.06-1.06Zm-1.06 1.06a4 4 0 0 0 5.657 0l-1.061-1.06a2.5 2.5 0 0 1-3.535 0l-1.061 1.06Zm7.07-7.07-.176-.177-1.06 1.06.176.178 1.06-1.061Z" fill="currentColor"/>
                                                    </svg>
                                                    <span className="ml-2">
                                                    Try it out
                                                </span>
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="space-y-10 lg:pl-16 xl:pl-24">
                                     <div className="rounded-2xl border border-zinc-100 p-6 dark:border-zinc-700/40">
                                         <h2 className="flex text-sm font-semibold text-zinc-900 dark:text-zinc-100">
                                             <svg viewBox="0 0 24 24" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" className="h-6 w-6 flex-none">
                                                 <path d="M2.75 9.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z" className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"/>
                                                 <path d="M3 14.25h6.249c.484 0 .952-.002 1.316.319l.777.682a.996.996 0 0 0 1.316 0l.777-.682c.364-.32.832-.319 1.316-.319H21M8.75 6.5V4.75a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2V6.5" className="stroke-zinc-400 dark:stroke-zinc-500"/>
                                             </svg>
                                             <span className="ml-3">
                                                 Work
                                             </span>
                                         </h2>
                                         <ol className="mt-6 space-y-4">
                                             {work.map((x, i) => {
                                                return (
                                                    <li className="flex gap-4">
                                                        <div className="relative mt-1 flex h-10 w-10 flex-none items-center justify-center rounded-full shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
                                                            <img alt="" loading="lazy" width="32" height="32" className="rounded-full bg-zinc-100 object-cover dark:bg-zinc-800 h-8 w-8"
                                                                 src={x.src} style={{color: "transparent"}} data-nimg={"1"}
                                                            />
                                                        </div>
                                                        <dl className="flex flex-auto flex-wrap gap-x-2">
                                                            <dt className="sr-only">
                                                                Company
                                                            </dt>
                                                            <dd className="w-full flex-none text-sm font-medium text-zinc-900 dark:text-zinc-100">
                                                                {x.company}
                                                            </dd>
                                                            <dt className="sr-only">
                                                                Role
                                                            </dt>
                                                            <dd className="text-xs text-zinc-500 dark:text-zinc-400">
                                                                {x.role}
                                                            </dd>
                                                            <dt className="sr-only">
                                                                Date
                                                            </dt>
                                                            <dd className="ml-auto text-xs text-zinc-400 dark:text-zinc-500" aria-label="2019 until Present">
                                                                <time dateTime={x.fromDateYear}>
                                                                    {x.fromDate}
                                                                </time>
                                                                <span aria-hidden="true" className={['Summer ', ""].includes(x.fromDate) ? "hidden" : ""}>
                                                                    —
                                                                </span>
                                                                <time dateTime={x.toDateYear}>
                                                                    {x.toDate}
                                                                </time>
                                                            </dd>
                                                        </dl>
                                                    </li>
                                                )
                                            })}

                                        </ol>
                                        <a className="inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 transition active:transition-none bg-zinc-50 font-medium text-zinc-900 hover:bg-zinc-100 active:bg-zinc-100 active:text-zinc-900/60 dark:bg-zinc-800/50 dark:text-zinc-300 dark:hover:bg-zinc-800 dark:hover:text-zinc-50 dark:active:bg-zinc-800/50 dark:active:text-zinc-50/70 group mt-6 w-full" href={"https://ishita-datta-resume.s3.ap-south-1.amazonaws.com/Ishita+Datta+Resume.pdf"} target={"_blank"}>
                                            Download CV
                                            <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="h-4 w-4 stroke-zinc-400 transition group-active:stroke-zinc-600 dark:group-hover:stroke-zinc-50 dark:group-active:stroke-zinc-50">
                                                <path d="M4.75 8.75 8 12.25m0 0 3.25-3.5M8 12.25v-8.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </a>
                                            </div>
                                    <div className="group relative flex flex-col items-start">
                                        <h2 className="text-base font-semibold text-zinc-800 dark:text-zinc-100 flex w-full">
                                            <Link to={"/volunteering"} className={"w-full"}>
                                                <div className={"absolute -inset-x-4 -inset-y-4 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-6 sm:rounded-2xl"} />
                                                <span className="relative z-10 flex items-center justify-between">
                                                    I also like Volunteering
                                                    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="stroke-purple-500 mx-6 -rotate-90 h-4 w-4 stroke-zinc-400 transition group-active:stroke-zinc-600 dark:group-hover:stroke-zinc-50 dark:group-active:stroke-zinc-50">
                                                        <path d="M4.75 8.75 8 12.25m0 0 3.25-3.5M8 12.25v-8.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>

                                            </Link>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;