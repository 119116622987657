import React from 'react';

const ArticleEngine = ({content}) => {
    if (content.type === 'p') {
        if (Object.keys(content).includes("content"))
            return (
                <p>
                    {content.content.map((x, i) => <ArticleEngine key={i} content={x}/>)}
                </p>
            );
        return (
            <p>
                {content.text}
            </p>
        );
    }

    else if (content.type === 'b') {
        if (Object.keys(content).includes("content"))
            return (
                <b>
                    {content.content.map((x, i) => <ArticleEngine key={i} content={x}/>)}
                </b>
            );
        return (
            <b>
                {content.text}
            </b>
        );
    }

    else if (content.type === 'span') {
        if (Object.keys(content).includes("content"))
            return (
                <span>
                    {content.content.map((x, i) => <ArticleEngine key={i} content={x}/>)}
                </span>
            );
        return (
            <span>
                {content.text}
            </span>
        );
    }

    else if (content.type === 'h1') {
        if (Object.keys(content).includes("content"))
            return (
                <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                    <span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                        {content.content.map((x, i) => <ArticleEngine key={i} content={x}/>)}
                    </span>
                </div>
            );

        return (
            <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                <span
                    className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                    {content.text}
                </span>
            </div>
        )
    } else if (content.type === 'h2') {
        if (Object.keys(content).includes("content"))
            return (
                <h2 className={"text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100"}>
                    {content.content.map((x, i) => <ArticleEngine key={i} content={x}/>)}
                </h2>
            );
        return (
            <h2 className={"text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100"}>
                {content.text}
            </h2>
        )
    }

    else if (content.type === 'li') {
        if (Object.keys(content).includes("content"))
            return (
                <li>
                    {content.content.map((x, i) => <ArticleEngine key={i} content={x}/>)}
                </li>
            );
        return (
            <li>
                {content.text}
            </li>
        )
    }

    else if (content.type === 'img') {
        if (Object.keys(content).includes("caption"))
            return (
                <>
                    <img alt={content.alt} loading={"lazy"} width={"1310"} height={"872"} src={content.src}
                         style={{color: "transparent"}}
                    />
                    <p className={"text-center"}>
                        {content.caption}
                    </p>
                </>
            )
        return (
            <img alt={content.alt} loading={"lazy"} width={"1310"} height={"872"} src={content.src}
                 style={{color: "transparent"}}
            />
        )
    }

    else if (content.type === 'ol')
        return (
            <ol className={"list-decimal pl-4"}>
                {content.content.map((x, i) => <ArticleEngine key={i} content={x}/>)}
            </ol>
        )

    else if (content.type === 'ul')
        return (
            <ol className={"list-disc pl-4"}>
                {content.content.map((x, i) => <ArticleEngine key={i} content={x}/>)}
            </ol>
        )

    else if (content.type === 'a') {
        if (Object.keys(content).includes('text'))
            return (
                <a href={content.href} className={"text-zinc-800 hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-400"}>
                    {content.text}
                </a>
            )
        return (
            <a href={content.href} className={"text-zinc-800 hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-400"}>
                {content.href}
            </a>
        )
    }
}

export default ArticleEngine;