// Modal.js
import React from 'react';

const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={"fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"} onClick={handleOverlayClick}>
            <div className={"p-0.5 bg-dark-gray rounded-lg"}>
                <div className={"relative w-full max-w-6xl p-8 bg-black text-white rounded-lg shadow-lg flex flex-col gap-8"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} onClick={onClose}
                         stroke="currentColor" className="cursor-pointer h-6 w-6 absolute top-6 right-6 text-gray-400 hover:text-gray-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                    </svg>

                    <h2 className="text-xl font-semibold text-center">
                        {title}
                    </h2>
                    <div>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Modal;