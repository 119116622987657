// export const courses = [
//     {
//         name: "SuccessFactors",
//         courses: [
//             {
//                 name: "SuccessFactors Employee Central - The Basics",
//                 link: "https://academy.integrtr.com/lms/course/30963-SuccessFactors-Employee-Central-The-Basics",
//                 description: "SAP SuccessFactors Employee Central is one of the top global core HR solutions that supports core HR processes and employee self-services for your total workforce. This course will cover the workings of SuccessFactors, what the architecture of the software is and why it is an important application in the HR space. In this course, we will be walking you through the very basic concepts that come under the HR solutions offered by SuccessFactors.",
//             },
//             {
//                 name: "SuccessFactors Employee Central - Part 2",
//                 link: "https://academy.integrtr.com/lms/course/31241-SuccessFactors-Employee-Central-Part-2",
//                 description: "In this course, we will be diving deeper into various Success Factors components and transactions and discussing certain concepts with live examples. We will be covering management of instances, permissions, workflows as well. This course also includes many demos and step-by-step tutorials of some of the most frequently used SuccessFactors features and components.",
//             }
//         ]
//     },
//     {
//         name: "SAP Integration Suite",
//         courses: [
//             {
//                 name: "Enterprise Integration With SAP Integration Suite",
//                 link: "https://academy.integrtr.com/lms/course/31044-Enterprise-Integration-with-SAP-Integration-Suite",
//                 description: "In this course, we are going to establish a complex integration scenario, discuss the road map to the solution, and begin our implementation on SAP Integration Suite. We will be focusing on learning in a practical manner and completing an end-to-end integration scenario using SAP Integration Suite, formerly known as SAP Cloud Integration (also referred to as SAP CPI).",
//             }
//         ]
//     },
//     {
//         name: "ServiceNow",
//         courses: [
//             {
//                 name: "A Beginner's Guide To ServiceNow",
//                 link: "https://academy.integrtr.com/lms/course/31303-A-Beginner-s-Guide-to-ServiceNow",
//                 description: "ServiceNow is a platform that provides services to automate the cloud-based workflow environment. It helps organizations to improve their overall business efficiencies and manage their services. This course will be taking you through the basics of ServiceNow. We will be covering some of the data types in ServiceNow, various components of the App Engine Studio, Service Portal and Integration Hub. The final module of the course also involves a hands-on development of a To Do application in ServiceNow.",
//             }
//         ]
//     }
// ]

export const courses = [
    {
        name: "SuccessFactors Employee Central - The Basics",
        concept: "SuccessFactors",
        link: "https://academy.integrtr.com/lms/course/30963-SuccessFactors-Employee-Central-The-Basics",
        description: "SAP SuccessFactors Employee Central is one of the top global core HR solutions that supports core HR processes and employee self-services for your total workforce. This course will cover the workings of SuccessFactors, what the architecture of the software is and why it is an important application in the HR space. In this course, we will be walking you through the very basic concepts that come under the HR solutions offered by SuccessFactors.",
    },
    {
        name: "SuccessFactors Employee Central - Part 2",
        concept: "SuccessFactors",
        link: "https://academy.integrtr.com/lms/course/31241-SuccessFactors-Employee-Central-Part-2",
        description: "In this course, we will be diving deeper into various Success Factors components and transactions and discussing certain concepts with live examples. We will be covering management of instances, permissions, workflows as well. This course also includes many demos and step-by-step tutorials of some of the most frequently used SuccessFactors features and components.",
    },
    {
        name: "Enterprise Integration With SAP Integration Suite",
        concept: "SAP Integration Suite",
        link: "https://academy.integrtr.com/lms/course/31044-Enterprise-Integration-with-SAP-Integration-Suite",
        description: "In this course, we are going to establish a complex integration scenario, discuss the road map to the solution, and begin our implementation on SAP Integration Suite. We will be focusing on learning in a practical manner and completing an end-to-end integration scenario using SAP Integration Suite, formerly known as SAP Cloud Integration (also referred to as SAP CPI).",
    },
    {
        name: "A Beginner's Guide To ServiceNow",
        concept: "ServiceNow",
        link: "https://academy.integrtr.com/lms/course/31303-A-Beginner-s-Guide-to-ServiceNow",
        description: "ServiceNow is a platform that provides services to automate the cloud-based workflow environment. It helps organizations to improve their overall business efficiencies and manage their services. This course will be taking you through the basics of ServiceNow. We will be covering some of the data types in ServiceNow, various components of the App Engine Studio, Service Portal and Integration Hub. The final module of the course also involves a hands-on development of a To Do application in ServiceNow.",
    }
]