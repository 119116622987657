import React from "react";

const MentorshipCard = ({title, description, image, link, cta}) => {
    return (
        <section className="md:col-span-1 md:items-baseline gap-8">
            <div className="group relative flex flex-col items-start">
                <h2 className="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                    <div className="absolute -inset-x-4 -inset-y-4 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 dark:bg-zinc-800/50 sm:-inset-x-4 sm:rounded-2xl"/>
                    <a href={link}>
                        <span className="absolute -inset-x-4 -inset-y-4 z-20 sm:-inset-x-4 sm:rounded-2xl"/>
                        <span className="relative z-10">
                            {title}
                        </span>
                    </a>
                </h2>
                <p className="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                    {description}
                </p>
                <div aria-hidden="true" className="relative z-10 mt-4 flex items-center text-sm font-medium text-purple-400">
                    {cta}
                    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="ml-1 h-4 w-4 stroke-current">
                        <path d="M6.75 5.75 9.25 8l-2.5 2.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
        </section>
    )
}

export default MentorshipCard;