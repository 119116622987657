import React from 'react';
import {projects, ongoingProjects} from "../content/projects";
import ProjectCard from "../components/projectCard";
import {designs} from "../content/designs";

const Projects = () => {
    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                <span className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    Creations
                                </span> born from a harmonious fusion of art, science and purpose
                            </h1>
                            <p className="mt-6 text-base text-zinc-600 dark:text-zinc-400">
                                I’ve worked on tons of little projects over the years but these are the ones that
                                I’m most proud of. Many of them are open-source, so if you see something that piques
                                your interest, check out the code and contribute if you have ideas for how it can be
                                improved.
                            </p>
                        </header>
                        <div className={"flex flex-col gap-4 justify-start"}>
                            <div className="mt-16 sm:mt-20 flex flex-col gap-8">
                                <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                                    <span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                                        Ongoing Projects
                                    </span>
                                </div>
                                <div className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
                                    {ongoingProjects.map((x, i) => {
                                        return (
                                            <ProjectCard
                                                key={i}
                                                title={x.name}
                                                description={x.description}
                                                link={x.link}
                                                imgSrc={x.src}
                                                ongoing
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="mt-16 sm:mt-20 flex flex-col gap-8">
                                <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                                    <span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                                        Past Projects
                                    </span>
                                </div>
                                <div className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
                                    {projects.map((x, i) => {
                                        return (
                                            <ProjectCard
                                                key={i}
                                                title={x.name}
                                                description={x.description}
                                                link={x.link}
                                                imgSrc={x.src}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="mt-16 sm:mt-20 flex flex-col gap-8">
                                <div className={"flex flex-row gap-4 text-3xl font-semibold"}>
                                    <span className={`bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text`}>
                                        UI Designs
                                    </span>
                                </div>
                                <div className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
                                    {designs.map((x, i) => {
                                        return (
                                            <ProjectCard
                                                key={i}
                                                title={x.name}
                                                description={x.description}
                                                link={x.link}
                                                imgSrc={x.src}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects;