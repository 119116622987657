import React, { useState, useEffect } from "react";

const CarouselItem = ({ item, index }) => {
    let sizeClass = "transform scale-80 opacity-50 w-[30%]";
    if (index === 1) sizeClass = "transform scale-100 opacity-100 w-[40%]";

    return (
        <div className={`transition-all duration-500 ease-in-out ${sizeClass}`}>
            <div className={`rounded-lg shadow-lg p-4 gap-2`}>
                <img
                    src={item.img}
                    alt={`Carousel Item ${index + 1}`}
                    className="h-full object-cover rounded-t-lg"
                />
                <p className="text-center font-semibold my-2">{item.name}</p>
                <p className={"text-sm text-zinc-600 dark:text-zinc-400 line-clamp-4"}>{item.description}</p>
                <a href={item.link} target={"_blank"} className={`${index === 1 ? "flex" : "hidden"} relative z-10 mt-4 items-center text-sm font-medium text-purple-400`}>
                    Read more
                    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" className="ml-1 h-4 w-4 stroke-current">
                        <path d="M6.75 5.75 9.25 8l-2.5 2.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
    );
}

const Carousel = ({items}) => {
    const [currentIndex, setCurrentIndex] = useState(1); // start from second item
    const [visibleItems, setVisibleItems] = useState([items[0], items[1], items[2]]);
    const length = items.length;

    const getPrevIndex = (index) => (index - 1 + length) % length;
    const getNextIndex = (index) => (index + 1) % length;

    const nextItem = () => {
        setCurrentIndex((prevIndex) => getNextIndex(prevIndex));
    };

    const prevItem = () => {
        setCurrentIndex((prevIndex) => getPrevIndex(prevIndex));
    };

    useEffect(() => {
        setVisibleItems([
            items[getPrevIndex(currentIndex)], // Previous item
            items[currentIndex],               // Active item (middle)
            items[getNextIndex(currentIndex)], // Next item
        ]);
    }, [currentIndex]);

    return (
        <div className="relative w-full h-[400px] flex items-center justify-center">
            {/* Previous Arrow */}
            <button
                onClick={prevItem}
                className="absolute left-2 z-10 p-2 text-white rounded-full"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24" strokeWidth={1.5} className="h-8 w-8 stroke-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>

            </button>

            {/* Carousel */}
            <div className="flex w-[85%] justify-center items-center transition-transform duration-1000 ease-in-out">
                {visibleItems.map((item, index) => (
                    <CarouselItem key={index} item={item} index={index} />
                ))}
            </div>

            {/* Next Arrow */}
            <button
                onClick={nextItem}
                className="absolute right-2 z-10 p-2 rounded-full"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8 stroke-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
            </button>
        </div>
    );
};

export default Carousel;