import React, {useState} from 'react';
import ishita from '../media/ishita-datta.jpg'
import {pages} from "../content/pages";
import {Link, useLocation} from "react-router-dom";

const Header = () => {
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <div className={"pt-6 sm:px-8 w-full"}>
            <div className={"mx-auto max-w-7xl lg:px-8"}>
                <div className={"relative px-4 sm:px-8 lg:px-12"}>
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <div className="relative flex gap-4">
                            <div className="flex flex-1">
                                <div className="h-10 w-10 rounded-full bg-white/90 p-0.5 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:ring-white/10">
                                    <a aria-label="Home" className="pointer-events-auto" href="/">
                                        <img alt={"Ishita Datta's profile picture"} width={"512"} height={"512"} data-nimg={"1"}
                                             className={"rounded-full bg-zinc-100 object-cover dark:bg-zinc-800 h-9 w-9"}
                                             src={ishita}
                                             style={{color: "transparent"}} />
                                    </a>
                                </div>
                            </div>
                            <div className={"flex flex-1 justify-end md:justify-center flex-col md:flex-row"}>
                                <div className="pointer-events-auto md:hidden" data-headlessui-state="">
                                    <button
                                        className="group flex items-center rounded-full bg-white/90 px-4 py-2 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10 dark:hover:ring-white/20"
                                        type="button" aria-expanded="false" data-headlessui-state=""
                                        id="headlessui-popover-button-:R2qb6:" onClick={() => setMenuOpen(!menuOpen)}
                                    >
                                        Menu
                                        <svg viewBox="0 0 8 6" aria-hidden="true" className="ml-3 h-auto w-2 stroke-zinc-500 group-hover:stroke-zinc-700 dark:group-hover:stroke-zinc-400">
                                            <path d="M1.75 1.75 4 4.25l2.25-2.5" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                                <nav className={`pointer-events-auto ${menuOpen?"flex flex-col": "hidden"} md:flex`}>
                                    <ul className={"flex flex-col absolute md:relative z-10 my-2 md:my-0 md:flex-row rounded-lg md:rounded-full bg-white/90 px-3 text-sm font-medium text-zinc-800 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:text-zinc-200 dark:ring-white/10"}>
                                        {pages.map((page, i) => {
                                            return (
                                                <li key={i}>
                                                    <Link className={`relative block px-3 py-2 transition ${location.pathname.includes(page.path)?"text-purple-500 dark:text-purple-400":"hover:text-purple-500 dark:hover:text-purple-400"}`} to={page.path}>
                                                        {page.name}
                                                        <span className={`${location.pathname === page.path?"":"hidden"} absolute inset-x-1 -bottom-px h-px bg-gradient-to-r from-purple-500/0 via-purple-500/40 to-purple-500/0 dark:from-purple-400/0 dark:via-purple-400/40 dark:to-purple-400/0`} />
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </nav>
                            </div>
                            <div className="flex justify-end md:flex-1">
                                <div className="pointer-events-auto hidden">
                                    <button type="button" aria-label="Toggle dark mode"
                                            className="group rounded-full bg-white/90 px-3 py-2 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20">
                                        <svg viewBox="0 0 24 24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" aria-hidden="true" className="h-6 w-6 fill-zinc-100 stroke-zinc-500 transition group-hover:fill-zinc-200 group-hover:stroke-zinc-700 dark:hidden [@media(prefers-color-scheme:dark)]:fill-teal-50 [@media(prefers-color-scheme:dark)]:stroke-teal-500 [@media(prefers-color-scheme:dark)]:group-hover:fill-teal-50 [@media(prefers-color-scheme:dark)]:group-hover:stroke-teal-600">
                                            <path d="M8 12.25A4.25 4.25 0 0 1 12.25 8v0a4.25 4.25 0 0 1 4.25 4.25v0a4.25 4.25 0 0 1-4.25 4.25v0A4.25 4.25 0 0 1 8 12.25v0Z"/>
                                            <path d="M12.25 3v1.5M21.5 12.25H20M18.791 18.791l-1.06-1.06M18.791 5.709l-1.06 1.06M12.25 20v1.5M4.5 12.25H3M6.77 6.77 5.709 5.709M6.77 17.73l-1.061 1.061" fill="none" />
                                        </svg>
                                        <svg viewBox="0 0 24 24" aria-hidden="true"
                                             className="hidden h-6 w-6 fill-zinc-700 stroke-zinc-500 transition dark:block [@media(prefers-color-scheme:dark)]:group-hover:stroke-zinc-400 [@media_not_(prefers-color-scheme:dark)]:fill-teal-400/10 [@media_not_(prefers-color-scheme:dark)]:stroke-teal-500">
                                            <path
                                                d="M17.25 16.22a6.937 6.937 0 0 1-9.47-9.47 7.451 7.451 0 1 0 9.47 9.47ZM12.75 7C17 7 17 2.75 17 2.75S17 7 21.25 7C17 7 17 11.25 17 11.25S17 7 12.75 7Z"
                                                strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Header;